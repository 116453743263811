import React from 'react'
import Layout from '../components/layout'
import commercial01 from '../assets/images/movie/commercial01.jpg'
import commercialsp from '../assets/images/movie/commercial_sp.jpg'
import Helmet from 'react-helmet'

import { Link } from 'gatsby'



const Commercial = () => {
  const siteTitle = 'Onodera Ryo |  commercials'

  return(
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
      </Helmet>
      <div id="main">
        <h2 class="name2">Commercial</h2>
        <p class="own_desc2">Nehanne MIHARA YASUHIRO 2019SS Short Movie<br></br>Director</p>
        <a class="own_desc2" href="https://youtu.be/Pmucj9VPcFo">https://youtu.be/Pmucj9VPcFo</a>
        <img src={commercial01} alt="commercial" class="commercial_img pc" />
        <img src={commercialsp} alt="commercial" class="commercial_img sp" />
      </div>
    </Layout>
  )
}

export default Commercial